import React from 'react';
import weddingPic from "./Categories/1 Wedding.png";
import dreamHousePic from "./Categories/2 Dream House.png";
import dreamCar from "./Categories/3 Dream Car.png";
import educationPic from "./Categories/4 Higher Education.png";
import retirePic from "./Categories/5 Retirement.png";
import overseas from "./Categories/6 Overseas Migration.png";
import travelPic from "./Categories/7 Travel and Vacation.png";
import business from "./Categories/8 Starting a Business.png";
import healthPic from "./Categories/9 Health and Fitness.png";


const CategorySelection = ({ categories, onSelectCategory }) => {

  const categoryImages = {
    'திருமணம்': weddingPic,
    'ஒரு வீடு வாங்க': dreamHousePic,
    'ஒரு கார் வாங்க': dreamCar,
    'உயர் கல்வி': educationPic,
    'ஓய்வூதியம்': retirePic,
    'வெளிநாடு செல்லல்': overseas,
    'சுற்றுலா பயணம்': travelPic,
    'ஒரு தொழில் தொடங்குதல்': business,
    'ஆரோக்கியம் பெறுதல்': healthPic,
  };

  const handleCategorySelect = (category) => {
    localStorage.setItem('selectedCategory', category);
    onSelectCategory(category);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-2xl mx-auto">
      <h2 className="text-xl font-bold mb-4 text-center">Select your Goal</h2>
      <div className="overflow-y-auto max-h-[calc(100vh-8rem)]">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-3">
          {categories.map((category) => (
            <div 
              key={category} 
              className="text-center border shadow-md hover:bg-gray-100 cursor-pointer p-4"
              onClick={() => handleCategorySelect(category)}
            >
              <img
                src={categoryImages[category]}
                alt={category}
                className="w-20 h-20 object-cover rounded-lg mb-2 mx-auto mt-3"
              />
              <button className="w-full flex justify-center items-center p-4">
              {category}
              </button>

            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategorySelection;
