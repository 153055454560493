import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Components/Homepage.jsx"; // Import your homepage component
import English from "./Components/English/English.jsx";
import Tamil from "./Components/Tamil/Tamil.jsx"; // Import other pages as needed

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} /> {/* Homepage route */}
        <Route path="/english" element={<English />} /> {/* Example other route */}
        <Route path="/tamil" element={<Tamil />} /> {/* Example other route */}
      </Routes>
    </Router>
  );
}

export default App;
