import React, { useEffect, useState } from 'react';
import './Quiz.css'; // Import the CSS file
import loadingGif from './gif.gif';

const Quiz = ({ questions, onFinish, onAnswer }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isFinishing, setIsFinishing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [showPhoneError, setShowPhoneError] = useState(false);

  const phoneNumberPattern = /^[0-9]{10}$/; // Example pattern for a 10-digit phone number

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
    setAnswers([...answers, { question: questions[currentQuestionIndex].question, answer }]);
    onAnswer(); // Call the onAnswer callback to update the progress bar
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setSelectedAnswer(null);
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setIsFinished(true);
      }
    }, 1000); // 1 second delay
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    if (phoneNumberPattern.test(e.target.value)) {
      setShowPhoneError(false);
    }
  };

  const handleFinish = async () => {
    let valid = true;

    if (!phoneNumberPattern.test(phoneNumber)) {
      setShowPhoneError(true);
      valid = false;
    }

    if (!valid) return;

    // Retrieve data from local storage
    const name = localStorage.getItem('quizUserName') || '';
    const category = localStorage.getItem('selectedCategory') || '';

    // Prepare data to send
    const dataToSend = {
      phoneNumber,
      email,
      name,
      category,
    };

    setIsFinishing(true);
    try {
      await fetch('https://hnbtamilapi.conscience.lk/api/saveData', { // Ensure this endpoint is set up on your backend
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });
      await onFinish(answers); // Assuming onFinish is a promise-based function
    } catch (error) {
      console.error('Error sending data to backend:', error);
    } finally {
      setIsFinishing(false);
      // Clear local storage
      localStorage.removeItem('quizUserName');
      localStorage.removeItem('selectedCategory');
      localStorage.removeItem('quizPhoneNumber');
      localStorage.removeItem('quizEmail');

      // Reset the state after finishing
      setIsFinished(false);
      setCurrentQuestionIndex(0);
      setAnswers([]);
      setPhoneNumber('');
      setEmail('');
    }
  };

  useEffect(() => {
    if (isFinished) {
      handleFinish();
    }
  }, [isFinished]);

  const progressPercentage = Math.floor((answers.length / questions.length) * 100);

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md w-full max-w-sm md:max-w-md mx-auto">
      <div>
        {!isFinished && (
          <div className="w-full bg-yellow-300 rounded-full h-3 md:h-4 mb-3 md:mb-4 relative">
            <div
              className={`h-3 md:h-4 rounded-full ${isFinishing ? 'bg-gray-500' : 'bg-blue-500'}`}
              style={{ width: `${progressPercentage}%` }}
            ></div>
            <div className="absolute inset-0 flex items-center justify-center text-black text-xs md:text-sm">
              {progressPercentage}%
            </div>
          </div>
        )}
        {isLoading ? (
          <div className="loader"></div>
        ) : isFinished ? (
          <>
            <h2 className="text-lg md:text-xl text-blue-500 flex items-center justify-center font-bold mb-3 md:mb-4">
              You're almost there!
            </h2>
            <p className="mb-3 md:mb-4 flex items-center justify-center">
              Enter your phone number and email to proceed.
            </p>
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className={`border p-2 mb-3 md:mb-4 w-full text-sm md:text-base ${showPhoneError ? 'border-red-500' : ''}`}
              placeholder="Enter your phone number"
              required
            />
            {showPhoneError && (
              <p className="text-red-500 text-xs md:text-sm mb-3 md:mb-4">
                Phone number must be a valid 10-digit number!
              </p>
            )}
            <input
              type="email"
              value={email}
              className="border p-2 mb-3 md:mb-4 w-full text-sm md:text-base"
              placeholder="Enter your email"
            />
            <button
              className="mt-4 w-full text-center p-2 bg-blue-500 text-white rounded hover:bg-blue-700 text-sm md:text-base"
              onClick={handleFinish}
              disabled={isFinishing}
            >
              {isFinishing ? 'Finishing...' : 'Check Your Personality'}
            </button>
          </>
        ) : (
          <>
            <h3 className="text-md md:text-lg font-bold mb-3 md:mb-4">
              {questions[currentQuestionIndex].question}
            </h3>
            <ul>
              {questions[currentQuestionIndex].answers.map((option, index) => (
                <li key={index}>
                  <button
                    className={`w-full text-left p-2 border-b hover:bg-gray-100 ${selectedAnswer === option ? 'font-bold' : ''} text-sm md:text-base`}
                    onClick={() => handleAnswerSelect(option)}
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}
        {/* Conditional rendering of the GIF popup when finishing */}
        {isFinishing && (
          <div className="gif-popup">
            <img src={loadingGif} alt="Loading..." className="gif-image" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Quiz;
