import React from "react";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="mt-20 md:mt-52 flex justify-center px-4 md:px-0 w-full">
      <div className="text-center bg-white p-6 rounded-lg shadow-md max-w-md w-full">
        <p className="mb-4 text-xl md:text-2xl font-bold">Welcome to the Quiz!</p>
        <p className="mb-4 text-sm md:text-base">Please select your language to begin the quiz.</p>
        <div className="flex flex-col md:flex-row justify-center mt-4 space-y-4 md:space-y-0 md:space-x-4">
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded w-full md:w-auto"
            onClick={() => navigate('/english')}
          >
            English
          </button>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded w-full md:w-auto"
            onClick={() => navigate('/tamil')}
          >
            Tamil
          </button>
        </div>
      </div>
    </div>
  );
  
};

export default HomePage;
